<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="py-2 d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">geslacht kaartkopers</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col cols="11">
              <bar-chart
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
              ></bar-chart>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Help from '../../Help.vue';
import BarChart from '../../../plugins/chartjs/BarChart.js';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData'],
  components: {
    Help,
    BarChart,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
    };
  },
  methods: {
    getData() {
      this.chart.loaded = false;

      const data = {
        purchasePeriods: this.widgetData,
      };

      if (!data.purchasePeriods.values.length > 0) {
        return;
      }

      var labels = data.purchasePeriods.values.map(function (e) {
        return e.gender;
      });

      var dataTickets = data.purchasePeriods.values.map(function (e) {
        return parseFloat(e.purchases).toFixed(2);
      });

      var backgroundColorBundle = ['#fff453', '#fff453', '#fff453', '#fff453', '#fff453'];

      labels.forEach(function (element, index) {
        if (element == 'Onbekend') {
          backgroundColorBundle[index] = '#EAEAEA';
        }
      });

      // Set chart options
      this.chart.options = {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'aantal kaartkopers',
            },
          },
          x: {
            title: {
              display: true,
              text: 'geslacht',
            },
          },
        },
        textOnTopOfBar: false,
      };

      // Set chart data
      this.chart.data = {
        labels: labels,
        datasets: [
          {
            data: dataTickets,
            backgroundColor: backgroundColorBundle,
            borderWidth: 3,
            weight: 3,
          },
        ],
      };
      // Load chart
      this.chart.loaded = true;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    widgetData() {
      this.getData();
    },
  },
};
</script>
