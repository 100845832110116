<template>
  <v-col
    :cols="colsMobile"
    :sm="colsTablet"
    :md="colsDesktop"
    class="d-flex fill-height"
  >
    <v-card
      class="px-4 py-0 mb-1"
      flat
      width="100%"
    >
      <v-container>
        <v-row class="block-head">
          <v-col
            cols="12"
            class="d-flex align-center justify-space-between"
          >
            <p class="primary--text font-weight-medium mb-0">leeftijdsopbouw</p>
            <help
              v-if="helpId != ''"
              bg-color=""
              :help-id="helpId"
            ></help>
          </v-col>
        </v-row>

        <v-row
          flex
          align="center"
          justify="center"
          class="py-2 flex-column flex-grow-1"
        >
          <template v-if="chart.loaded">
            <v-col cols="12">
              <bar-chart
                v-if="chart.loaded"
                :chart-data="chart.data"
                :chart-options="chart.options"
                :plugins="chart.plugins"
              ></bar-chart>

              <div class="widget-average d-flex flex-column align-center justify-center my-4">
                <h4>Gemiddelde leeftijd {{ Math.round(widgetData.average) }}</h4>
                <span v-if="widgetData.averageComparison != null">
                  {{ `vergelijking: ${Math.round(widgetData.averageComparison)}` }}
                </span>
              </div>
            </v-col>
          </template>

          <template v-else>
            <p class="primary--text font-weight-medium mb-0">geen data beschikbaar</p>
          </template>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import Help from '../../Help.vue';
import BarChart from '../../../plugins/chartjs/BarChartDatalabels.js';

export default {
  props: ['helpId', 'colsMobile', 'cols-Tablet', 'colsDesktop', 'widgetData', 'fillHeight'],
  components: {
    Help,
    BarChart,
  },
  data() {
    return {
      chart: {
        loaded: false,
        data: {},
        options: {},
      },
    };
  },
  methods: {
    getData() {
      this.chart.loaded = false;

      const data = {
        ages: this.widgetData,
      };

      if (!data.ages.values.length > 0) {
        return;
      }

      var labels = data.ages.values.map(function (e) {
        return e.age;
      });

      var dataTickets = data.ages.values.map(function (e) {
        return parseFloat(e.rate).toFixed(2);
      });

      var dataPurchases = data.ages.values.map(function (e) {
        return e.purchases;
      });

      if (data.ages.comparison != null) {
        var dataTicketsCompared = data.ages.comparison.map(function (e) {
          return e.rate;
        });

        var dataPurchasesCompared = data.ages.comparison.map(function (e) {
          return e.purchases;
        });
      }

      var backgroundColorBundle = ['#fff453', '#0abfb8', '#021e73', '#D92853', '#782562', '#FF8F2E', '#EAEAEA'];
      var colorBundle = ['#021e73', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'];

      labels.forEach(function (element, index) {
        if (element == 'Onbekend') {
          backgroundColorBundle[index] = '#EAEAEA';
          colorBundle[index] = '#000';
        }
      });

      // Set chart options
      this.chart.options = {
        layout: {
          padding: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
            x: {
              stacked: true,
              max: 100,

            },
            y: {
              stacked: true,
            },
          },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            textAlign: 'left',
            title: {
              display: true,
              color: '#021e72',
              font: {
                size: 14,
              },
              padding: 10,
            },
          },
          datalabels: {
            formatter: function (value) {
              if (value > 5) {
                return Math.ceil(value);
              } else {
                return '';
              }
            },
          },
        },
      };

      var datasets = labels.map(function (label, index) {
        return {
          label: label,
          backgroundColor: backgroundColorBundle[index],
          data: [dataTickets[index], dataTicketsCompared != null ? dataTicketsCompared[index] : null],
          dataPurchases: [dataPurchases[index], dataPurchasesCompared != null ? dataPurchasesCompared[index] : null],
          stack: 'Stack 1',
          datalabels: {
            color: colorBundle[index],
          },
          barThickness: 60,
          tooltip: {
            enabled: true,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
              label: function (tooltipItem) {
                console.log(tooltipItem.dataset)
                var dataPercentage = parseFloat(tooltipItem.dataset.data[tooltipItem.dataIndex]).toFixed(2);
                var data = tooltipItem.dataset.dataPurchases[tooltipItem.dataIndex];
                var label = `${tooltipItem.dataset.label}: ${dataPercentage}% / ${data}`;
                return label;
              },
            },
          },
        };
      });

      // Set chart data
      this.chart.data = {
        labels: ['eigen theater', 'vergelijking'],
        datasets: datasets,
      };

      // Load chart
      this.chart.loaded = true;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    widgetData() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
