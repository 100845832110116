<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="1147"
            ></help>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <!-- Filters -->
      <v-col cols="12">
        <BenchmarkDetailFilters
          :user="user"
          v-model="filterWidgets"
          :filter-options="filterOptions"
        ></BenchmarkDetailFilters>
      </v-col>
    </v-row>
    <v-row>
      <!-- Basis informatie -->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="hw-print"
      >
        <basic-info-card :production="this.production"></basic-info-card>
      </v-col>

      <!-- Bezettingsgraad -->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 ma-0 d-flex flex-grow-1 align-stretch hw-print"
      >
        <v-col class="d-flex">
          <Occupancy-meta-card :occupancy="this.benchmarkOccupancyRate"></Occupancy-meta-card>
        </v-col>
      </v-col>

      <!-- Leeftijd per klant-->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 ma-0 d-block d-sm-flex d-md-block hw-print"
      >
        <age-visitors
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1133"
          :widget-data="benchmarkCustomerAges"
        ></age-visitors>
      </v-col>

      <!-- Moment van aankoop -->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 ma-0 d-block d-sm-flex d-md-block"
      >
        <purchase-periods
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1134"
          :widget-data="benchmarkPurchasePeriods"
          :fill-height="true"
        ></purchase-periods>
      </v-col>

      <!-- Prijssoorten -->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 ma-0 d-block d-sm-flex d-md-block"
      >
        <sales-types
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1135"
          :widget-data="benchmarkTicketSorts"
        ></sales-types>
      </v-col>

      <!-- Kaarten per klant -->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 ma-0 d-block d-sm-flex d-md-block"
      >
        <tickets-per-customer
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1137"
          :widget-data="benchmarkTicketsPerCustomer"
        ></tickets-per-customer>
      </v-col>

      <!-- Kaartverkoop over tijd  -->
      <!-- <v-col
        cols="12"
        sm="12"
        md="12"
        class="pa-0 ma-0 d-flex hw-print"
      >
        <ticket-sales-over-time
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1140"
          :widget-data="benchmarkTicketSalesOverTime"
        ></ticket-sales-over-time>
      </v-col> -->

      <!-- Geslacht kaartkopers -->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 ma-0 d-flex hw-print"
      >
        <buyer-genders
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1139"
          :widget-data="benchmarkBuyerGenders"
        ></buyer-genders>
      </v-col>

      <!-- Dag van aankoop  -->
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="pa-0 ma-0 d-flex hw-print"
      >
        <day-of-purchase
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1140"
          :widget-data="benchmarkDayOfPurchases"
        ></day-of-purchase>
      </v-col>

      <!-- Geografische spreiding  -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pa-0 ma-0 d-flex hw-print"
      >
        <buyer-distances
          :cols-desktop="12"
          :cols-tablet="12"
          :cols-mobile="12"
          :help-id="1140"
          :widget-data="benchmarkBuyerDistances"
        ></buyer-distances>
      </v-col>
    </v-row>

    <!-- Table -->
    <v-data-table
      :headers="benchmarkedTheatersHeaders"
      :items="tableBenchmarkProductionsFiltered"
      :mobile-breakpoint="0"
      :loading="tableBenchmarkProductionsLoading"
      :items-per-page="-1"
      hide-default-footer
      class="table-action row-is-clickable mt-5"
      fixed-header
      :height="tableBenchmarkProductionsFiltered.length > 10 ? '55vh' : ''"
    >
      <template v-slot:[`item.firstStartDate`]="{ item }">
        {{ getDateTimeView(item.firstStartDate) }}
      </template>
      <template v-slot:[`item.lastStartDate`]="{ item }">
        {{ getDateTimeView(item.lastStartDate) }}
      </template>
      <v-data-footer></v-data-footer>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import functions from '../../services/functions.service';
import BenchmarkDetailFilters from './components/BenchmarkDetail/Topfilters.vue';
import BasicInfoCard from './components/BenchmarkDetail/BasicInfoCard.vue';
import OccupancyMetaCard from './components/occupancyMetaCard';

// Widgets
import AgeVisitors from '../../components/widgets/Benchmark/AgeVisitors.vue';
import PurchasePeriods from '../../components/widgets/Benchmark/PurchasePeriod.vue';
import TicketsPerCustomer from '../../components/widgets/Benchmark/TicketsPerCustomer.vue';
import SalesTypes from '../../components/widgets/Benchmark/SalesTypes.vue';
import BuyerGenders from '../../components/widgets/Benchmark/BuyerGenders.vue';
import DayOfPurchase from '../../components/widgets/Benchmark/DayOfPurchase.vue';
import BuyerDistances from '../../components/widgets/Benchmark/BuyerDistances.vue';

export default {
  name: 'BenchmarkProductionsBenchmark',
  components: {
    Help,
    BenchmarkDetailFilters,
    BasicInfoCard,
    OccupancyMetaCard,
    AgeVisitors,
    PurchasePeriods,
    TicketsPerCustomer,
    SalesTypes,
    BuyerGenders,
    DayOfPurchase,
    BuyerDistances,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      benchmarkedTheaters: [],
      benchmarkOccupancyRate: {},
      breadcrumbs: [
        {
          text: 'benchmark producties',
          disabled: false,
          href: '/benchmark/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      production: {
        id: 0,
        title: '',
        productionNumber: '',
        genre: '',
        firstStartDate: '',
        lastStartDate: '',
        playDay: '',
      },
      filterWidgets: {},
      filterOptions: {
        salesTypes: [],
        originalSalesTypes: [],
        buyerAges: [],
        buyerLoyalties: [],
        buyerBehaviors: [],
        purchasePeriods: [],
        buyerGenders: [],
        buyerDistances: [],
        dayOfPurchases: [],
        ticketCounts: [],
      },
      benchmarkCustomerAges: [],
      benchmarkTicketSorts: [],
      benchmarkPurchasePeriods: [],
      benchmarkTicketsPerCustomer: [],
      benchmarkTicketSalesOverTime: [],
      benchmarkBuyerGenders: [],
      benchmarkDayOfPurchases: [],
      benchmarkBuyerDistances: [],
      tableBenchmarkProductionsFiltered: [],
      tableBenchmarkProductionsLoading: true,
      benchmarkedTheatersHeaders: [
        { text: 'theaternaam', value: 'name' },
        { text: 'plaats', value: 'city' },
        { text: 'aantal voorstellingen', value: 'performances' },
        { text: 'capaciteit', value: 'capacity' },
        { text: 'datum eerste voorstelling', value: 'firstStartDate' },
        { text: 'datum laatste voorstelling', value: 'lastStartDate' },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('Benchmark');
    this.getBenchmarkedProductions();
  },
  methods: {
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    getDateTimeView(value) {
      return functions.getDateTimeView(value);
    },
    getBenchmarkedProductions: function () {

      // var vm = this;
      this.benchmarkedTheaters = [];
      this.tableBenchmarkProductionsFiltered = [];
      this.tablePublicMonitorLoading = true;

      var params = new URLSearchParams();
      params.append('productionNumber', this.$route.params.productionNumber);
      this.$route.query.theaterIds.forEach(function (theater) {
        params.append('theaterIds', theater);
      });
      params.append('StartDate', this.$route.query.startDate);
      params.append('EndDate', this.$route.query.endDate);
      params.append('BuyerAge', this.filterWidgets.buyerAge != null ? this.filterWidgets.buyerAge : '');
      params.append(
        'PurchasePeriod',
        this.filterWidgets.purchasePeriod != null ? this.filterWidgets.purchasePeriod : ''
      );
      params.append('SalesType', this.filterWidgets.salesType != null ? this.filterWidgets.salesType : '');
      params.append('BuyerBehavior', this.filterWidgets.buyerBehavior != null ? this.filterWidgets.buyerBehavior : '');
      params.append('TicketCount', this.filterWidgets.ticketCount != null ? this.filterWidgets.ticketCount : '');
      params.append('BuyerGender', this.filterWidgets.buyerGender != null ? this.filterWidgets.buyerGender : '');
      params.append('DayOfPurchase', this.filterWidgets.dayOfPurchase != null ? this.filterWidgets.dayOfPurchase : '');
      params.append('BuyerDistance', this.filterWidgets.buyerDistance != null ? this.filterWidgets.buyerDistance : '');

      var request = {
        params: params,
      };

      ApiService.getWithObject('Benchmark/GetBenchmark', request)
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();

          const production = response.data.result;

          this.production.id = production.id;
          this.production.title = production.title;
          this.production.productionNumber = production.number;
          this.production.genre = production.genre;
          this.production.firstStartDate = production.firstStartDate;
          this.production.lastStartDate = production.lastStartDate;

          this.breadcrumbs[1].text = this.production.title;

          this.benchmarkOccupancyRate = response.data.result.occupancy;
          this.benchmarkCustomerAges = response.data.result.buyerAges;
          this.benchmarkPurchasePeriods = response.data.result.purchasePeriods;
          this.benchmarkTicketSorts = response.data.result.salesTypes;
          this.benchmarkTicketsPerCustomer = response.data.result.tickets;
          this.benchmarkTicketSalesOverTime = response.data.result.salesOverTimes;
          this.benchmarkBuyerGenders = response.data.result.buyerGenders;
          this.benchmarkDayOfPurchases = response.data.result.dayOfPurchases;
          this.benchmarkBuyerDistances = response.data.result.buyerDistances;

          //FILTERS
          this.filterOptions.buyerAges = response.data.result.buyerAgeSelect;
          this.filterOptions.purchasePeriods = response.data.result.purchasePeriodSelect;
          this.filterOptions.salesTypes = response.data.result.salesTypeSelect;
          this.filterOptions.buyerLoyalties = response.data.result.buyerLoyaltySelect;
          this.filterOptions.buyerBehaviors = response.data.result.buyerBehaviorSelect;
          this.filterOptions.buyerGenders = response.data.result.buyerGenderSelect;
          this.filterOptions.buyerDistances = response.data.result.buyerDistanceSelect;
          this.filterOptions.ticketCounts = response.data.result.ticketSelect;
          this.filterOptions.compareWithOptions = response.data.result.compareWithSelect;
          this.filterOptions.dayOfPurchases = response.data.result.dayOfPurchaseSelect;

          this.tableBenchmarkProductionsFiltered = production.theaters;
          this.tableBenchmarkProductionsLoading = false;
        })
        .catch((error) => {
          //eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['storedFilterPeriod']),
  },
  watch: {
    filterWidgets: {
      handler: function () {
        this.getBenchmarkedProductions();
      },
      deep: true,
    },
  },
};
</script>
